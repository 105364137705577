/* eslint-disable no-console */
import { FormType, FormErrors, FrontendRoutes } from "@simplyk/common";
import { v4 } from "uuid";

import { AppFrontendProvider } from "../../components/AppFrontendProvider/AppFrontendProvider";
import { DonationFormHead } from "../../components/Head/DonationFormHead";
import { DonationFormV2Page } from "../../features/FormV2/DonationFormV2Page";
import { Locales, OrganizationObject } from "../../gql/gql-types";
import { FrontendDonationFormProvider } from "../../providers/FrontendDonationFormProvider";

import { getSSRDonationForm, getTranslation, provideOrganization } from "@/helpers/getStaticProps";
import { usePageScrollUp } from "@/hooks/usePageScrollUp";
import { useTheme } from "@/hooks/useTheme";
import { ServerSideContext } from "@/types/app";
import { DonationFormOutput } from "@/types/trpc";

type DonationFormWithOrganization = DonationFormOutput & {
  organization: OrganizationObject;
};
export interface DonationFormPageProps {
  donationForm: DonationFormWithOrganization;
}

const DonationFormPage = ({ donationForm }: DonationFormPageProps) => {
  usePageScrollUp();
  const theme = useTheme(donationForm.color);

  if (!donationForm.donationFormFields) {
    return null;
  }

  return (
    <>
      {/* Nothing should wrap or block the Head component */}
      <DonationFormHead donationForm={donationForm} />
      <AppFrontendProvider
        theme={theme}
        organization={donationForm.organization}
        formType={FormType.DonationForm}
        formData={donationForm}
        fields={donationForm.donationFormFields}
        isEmbed={false}
        shouldDisplayLogin={false}
        isFundraiser={false}
        themeColor={donationForm.color}
      >
        <FrontendDonationFormProvider donationForm={donationForm}>
          <DonationFormV2Page />
        </FrontendDonationFormProvider>
      </AppFrontendProvider>
    </>
  );
};

export const getServerSideProps = async (context: ServerSideContext<{ donationFormId: string }>) => {
  const requestId = (context.req?.headers?.["x-request-id"] as string) || v4();
  const { donationFormId } = context.params;

  const locale = context.locale.toUpperCase() as Locales;

  try {
    const [donationFormResponse, translations] = await Promise.all([
      getSSRDonationForm({ donationFormIdOrUrlPath: donationFormId, requestId }),
      getTranslation(context.locale),
    ]);

    if (donationFormResponse.error) {
      const code = donationFormResponse.error.code;

      if (code === FormErrors.FormDeleted) {
        console.log(`Form deleted - redirecting to gone donationFormId=${donationFormId} ssrId=${requestId}`);
        return { redirect: { destination: FrontendRoutes.Gone } };
      }

      console.log(
        `NEXT_ERROR: form not found - redirecting to notFound donationFormId=${donationFormId} ssrId=${requestId}`
      );
      return { redirect: { destination: FrontendRoutes.NotFound } };
    }

    return {
      props: {
        ...translations,
        donationForm: donationFormResponse.data.organization
          ? donationFormResponse.data
          : provideOrganization(donationFormResponse.data, locale),
      },
    };
  } catch (error) {
    console.log(
      `NEXT_ERROR: request failed - redirecting to notFound donationFormId=${donationFormId} ssrId=${requestId}`,
      error
    );
    return { redirect: { destination: FrontendRoutes.NotFound } };
  }
};

export default DonationFormPage;
